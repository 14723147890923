import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';

// Props: title, date, link, image, text, button
function ElementClientsThumbnail(props)
{
    return (
        <div className="col-12 col-md-4">
            <Link to={props.link} target="_blank">
                <div className="panel-content-clients">
                    { props.image !== '' &&
                        <div className="panel-content-clients-image" style={{backgroundImage: 'url('+props.image+')'}}></div>
                    }

                    <div className="panel-content-clients-container-top">
                        <h2 className="panel-content-clients-title">
                            {props.title}
                        </h2>
                        <div>
                            <span className="panel-content-clients-date">{props.date}</span>
                        </div>
                        <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text)}}></div>
                    </div>

                    <div className="panel-content-clients-container-bottom"> 
                        <div className="panel-button-clients">{props.button}</div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8004/backend/levelfinance/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: to, text
function ElementFooterLink(props)
{
    return (
        <Link to={props.to} className="footer-link">{props.text}</Link>
    );
}

// Props: text
function ElementHeaderH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="panel-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="panel-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderH2(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="panel-title-h2-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="panel-title-h2-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderH2Checkmark(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="panel-title-h2-mobile"><span className="panel-checks-checkmark-mobile"></span>{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="panel-title-h2-desktop"><span className="panel-checks-checkmark-desktop"></span>{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: title, date, text, image, slug
function ElementNewsThumbnail(props)
{
    return (
        <div className="col-12 col-md-4">
            <Link to={props.slug}>
                <div className="panel-content-news">
                    { props.image !== '' &&
                        <div className="panel-content-news-image" style={{backgroundImage: 'url('+props.image+')'}}></div>
                    }

                    <div className="panel-content-news-container-top">
                        <h2 className="panel-content-news-title">
                            {props.title}
                        </h2>
                        <div>
                            <span className="panel-content-news-date">{props.date}</span>
                        </div>
                        <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text)}}></div>
                    </div>

                    <div className="panel-content-news-container-bottom"> 
                        <div className="panel-button-news">{props.button}</div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

// Props: title, text
function ElementPanelHeader(props)
{
    return (
        <div className="col">
            <div className="panel-header">
                <ElementHeaderH2 text={props.title} />
                <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
            </div>
        </div>
    );
}

// Props: name, title, image, text_profile, phone, email, linkedin, translations
function ElementProfileInternal(props)
{
    const [rotated, rotated_set] = useState(false);
    const prevent_rotated = useRef(false);

    function rotate()
    {
        if (prevent_rotated.current === false)
            rotated_set(!rotated);
        else
        {
            // Default to frontside
            prevent_rotated.current = false;
            rotated_set(false);
        }
    }
    
    function prevent_rotate()
    {
        prevent_rotated.current = true;
    }

    let rotate_class = '';
    let scroll_class = 'panel-content-profile-container-fullscroll-disabled';
    if (rotated === true)
    {
        rotate_class = 'panel-content-profile-rotated';
        scroll_class = 'panel-content-profile-container-fullscroll';
    }

    return (
        <div className="col-12 col-md-6 col-lg-4" id={props.name.replace(/ /g, '')}>
            <div className="panel-content-profile-perspective" onClick={rotate}>
                <div className={'panel-content-profile-rotate '+rotate_class}>
                    <div className="panel-content-profile-front">
                        <div className="panel-content-profile-image" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        
                        <div className="panel-content-profile-container-fronttop">
                            <h3 className="panel-content-profile-name">
                                {props.name}<span className="panel-content-profile-logo-levelfinance"></span>
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                            </h4>
                            <div className="panel-content-profile-buttons">
                                { props.phone !== null && props.phone !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={'tel:'+props.phone} onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                <div className="panel-content-profile-icon-phone"></div>
                                                {props.phone}
                                            </div>
                                        </a>
                                    </div>
                                }

                                { props.email !== null && props.email !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={'mailto:'+props.email} onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                <div className="panel-content-profile-icon-email"></div>
                                                Mail
                                            </div>
                                        </a>
                                    </div>
                                }

                                { props.linkedin !== null && props.linkedin !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={props.linkedin} target="_blank" rel="noreferrer" onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                <div className="panel-content-profile-icon-linkedin"></div>LinkedIn
                                            </div>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="panel-content-profile-container-frontbottom"> 
                            <div className="panel-button-profile">{props.translations['content_profiles_description']}</div>
                        </div>
                    </div>
                    <div className="panel-content-profile-back">
                        <div className="panel-content-profile-container-back">
                            <h3 className="panel-content-profile-name">
                                {props.name}<span className="panel-content-profile-logo-levelfinance"></span>
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                            </h4>
                            <div className={scroll_class}>
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text_profile)}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Props: name, title, image, text_profile, text_qualities, availablefrom, availablenow, translations
function ElementProfileConsultant(props)
{
    const [rotated, rotated_set] = useState(false);
    const prevent_rotated = useRef(false);

    function rotate()
    {
        if (prevent_rotated.current === false)
            rotated_set(!rotated);
        else
        {
            // Default to backside
            prevent_rotated.current = false;
            rotated_set(false);
        }
    }

    function prevent_rotate()
    {
        prevent_rotated.current = true;
    }

    function scroll_to_profileinternal(id)
    {
        prevent_rotate();

        let element = document.getElementById(id);
        window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY - 60
        });
    }

    let rotate_class = '';
    let scroll_class = 'panel-content-profile-container-fullscroll-disabled';
    if (rotated === true)
    {
        rotate_class = 'panel-content-profile-rotated';
        scroll_class = 'panel-content-profile-container-fullscroll';
    }

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="panel-content-profile-perspective" onClick={rotate}>
                <div className={'panel-content-profile-rotate '+rotate_class}>
                <div className="panel-content-profile-front">
                        <div className="panel-content-profile-image" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        
                        <div className="panel-content-profile-container-fronttop">
                            <h3 className="panel-content-profile-name">
                                {props.name}<span className="panel-content-profile-logo-levelfinance"></span>
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                                
                                { props.availablefrom !== null &&
                                    <div className="panel-content-profile-available-container">
                                        <div className="panel-content-profile-available-date">
                                            {props.translations['content_profiles_availablefrom']} {props.availablefrom}
                                        </div>
                                    </div>
                                }

                                { props.availablenow === true &&
                                    <div className="panel-content-profile-available-container">
                                        <div className="panel-content-profile-available-date">
                                            {props.translations['content_profiles_availablenow']}
                                        </div>
                                    </div>
                            }
                            </h4>

                            <div className="panel-content-profile-buttons">
                                { props.linkedin !== null && props.linkedin !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={props.linkedin} target="_blank" rel="noreferrer" onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                <div className="panel-content-profile-icon-linkedin"></div>LinkedIn
                                            </div>
                                        </a>
                                    </div>
                                }

                                <div className="panel-content-profile-button-container">
                                    <div className="panel-content-profile-button-businessmanager" onClick={() => scroll_to_profileinternal(props.businessmanager.replace(/ /g, ''))}>
                                        {props.translations['content_profiles_contactperson']}: {props.businessmanager}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-content-profile-container-frontbottom"> 
                            <div className="panel-button-profile">{props.translations['content_profiles_description']}</div>
                        </div>
                    </div>
                    <div className="panel-content-profile-back">
                        <div className="panel-content-profile-container-back">
                            <h3 className="panel-content-profile-name">
                                {props.name}<span className="panel-content-profile-logo-levelfinance"></span>
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                            </h4>
                            <div className={scroll_class}>
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text_profile)}}></div>

                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text_qualities)}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Props: name, title, company, image, text_profile, email, externallink, linkedin, translations
function ElementProfileConsultantExternal(props)
{
    const [rotated, rotated_set] = useState(false);
    const prevent_rotated = useRef(false);
    
    function rotate()
    {
        if (prevent_rotated.current === false)
            rotated_set(!rotated);
        else
        {
            // Default to backside
            prevent_rotated.current = false;
            rotated_set(false);
        }
    }

    function prevent_rotate()
    {
        prevent_rotated.current = true;
    }
    
    let rotate_class = '';
    let scroll_class = 'panel-content-profile-container-fullscroll-disabled';
    if (rotated === true)
    {
        rotate_class = 'panel-content-profile-rotated';
        scroll_class = 'panel-content-profile-container-fullscroll';
    }

    let perspective_class = 'panel-content-profile-perspective';
    /*
    if (props.name === 'Guus Thissen')
    {
        perspective_class = 'panel-content-profile-perspective-extended';
    }
    */

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className={perspective_class} onClick={rotate}>
                <div className={'panel-content-profile-rotate '+rotate_class}>
                    <div className="panel-content-profile-front">
                        <div className="panel-content-profile-image" style={{backgroundImage: 'url('+props.image+')'}}></div>

                        <div className="panel-content-profile-container-fronttop">
                            <h3 className="panel-content-profile-name">
                                {props.name}
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                                
                                <div className="panel-content-profile-available-container">
                                    <div className="panel-content-profile-available-date">
                                        {props.translations['content_profiles_consultantexternal_cooperate']}
                                    </div>
                                </div>
                            </h4>
                            <div className="panel-content-profile-buttons-consultantexternal">
                                { props.externallink !== null && props.externallink !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={props.externallink} target="_blank" rel="noreferrer" onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                {props.company}
                                            </div>
                                        </a>
                                    </div>
                                }

                                { props.linkedin !== null && props.linkedin !== '' &&
                                    <div className="panel-content-profile-button-container">
                                        <a href={props.linkedin} target="_blank" rel="noreferrer" onClick={prevent_rotate}>
                                            <div className="panel-content-profile-button">
                                                <div className="panel-content-profile-icon-linkedin"></div>LinkedIn
                                            </div>
                                        </a>
                                    </div>
                                }
                            </div>

                            { /* props.name === 'Guus Thissen' &&
                                <div className="panel-content-profile-thissenai-container">
                                    Sales Recommendation AI
                                </div>
                            } */ }
                            { /* props.name === 'Guus Thissen' &&
                                <div className="panel-content-profile-thissenai-container">
                                    <div className="panel-content-profile-thissenai-title">Sales Recommendation AI</div>
                                    Koppel klanten aan producten met onze AI technologie
                                </div>
                            */ }
                        </div>
                        <div className="panel-content-profile-container-frontbottom"> 
                            <div className="panel-button-profile">{props.translations['content_profiles_description']}</div>
                            
                            { /*
                            { props.overrule_available !== null &&
                                <div className="d-block d-lg-none d-xl-block">
                                    <div className="panel-content-profile-available-bottom">
                                        {props.translations['content_profiles_consultantexternal_cooperate']}
                                    </div>
                                </div>
                            }
                            */ }
                        </div>
                    </div>
                    <div className="panel-content-profile-back">
                        <div className="panel-content-profile-container-back">
                            <h3 className="panel-content-profile-name">
                                {props.name}
                            </h3>
                            <h4 className="panel-content-profile-title">
                                {props.title}
                            </h4>
                            <div className={scroll_class}>
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text_profile)}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Props: type (light, gray or dark), category, title
function ElementSlideshowContent(props)
{
    let style = {};
    switch (props.type)
    {
        case 'white':   style = {
                            color: '#000000'
                        }
                        break;

        case 'gray':    style = {
                            color: '#000000'
                        }
                        break;

        case 'linkedin':style = {
                            color: '#ffffff'
                        }
                        break;

        default:        style = {
                            color: '#000000'
                        }
                        break;
    }
    
    let buttons = [];
    if (props.buttons !== null)
    {
        for (let i = 0; i < props.buttons.length; i++)
        {
            if (props.buttons[i]['buttontype'] === 'link')
                buttons.push(<a href={props.buttons[i]['to']} className={'slideshow-button-'+props.buttons[i]['class']} key={i}>{props.buttons[i]['text']}<span className="banner-link-external-icon"></span></a>);
            
            if (props.buttons[i]['buttontype'] === 'hash')
                buttons.push(<React.Fragment key={i}><HashLink smooth to={'#'+props.buttons[i]['to']} className={'slideshow-button-'+props.buttons[i]['class']}>{props.buttons[i]['text']}</HashLink></React.Fragment>);
        }
    }
    
    return (
        <div className="row" style={style}>
            <div className="col-12 col-md-6">
                <div className="slideshow-content-center">

                    { /* XS, SM */ }
                    <div className="d-block d-md-none">
                        <span className={'slideshow-content-category slideshow-content-type-'+props.type}>{props.category}</span>
                        <h1 className={'slideshow-content-title-mobile slideshow-content-type-'+props.type}>{props.title}</h1>
                        <p className={'slideshow-content-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                        { props.slide === 0 && props.profiles.length >= 6 &&
                            <div className="slideshow-profiles-subcontainer">
                                <div className="slideshow-profiles-subcontainer-top-mobile">
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[0]+')'}}></div>
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[1]+')'}}></div>
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[2]+')'}}></div>
                                </div>
                                <div className="slideshow-profiles-subcontainer-bottom-mobile">
                                    <div className="slideshow-profiles-circle-mobile-offset"></div>
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[3]+')'}}></div>
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[4]+')'}}></div>
                                    <div className="slideshow-profiles-circle-mobile" style={{backgroundImage: 'url('+props.profiles[5]+')'}}></div>
                                </div>
                            </div>
                        }
                        
                        {buttons}
                    </div>
                    
                    { /* MD */ }
                    <div className="d-none d-md-block d-lg-none">
                        <span className={'slideshow-content-category slideshow-content-type-'+props.type}>{props.category}</span>
                        <h1 className={'slideshow-content-title-tablet slideshow-content-type-'+props.type}>{props.title}</h1>
                        <p className={'slideshow-content-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                        {buttons}
                    </div>

                    { /* LG, XL, XXL */ }
                    <div className="d-none d-lg-block">
                        <span className={'slideshow-content-category slideshow-content-type-'+props.type}>{props.category}</span>
                        <h1 className={'slideshow-content-title-desktop slideshow-content-type-'+props.type}>{props.title}</h1>
                        <p className={'slideshow-content-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                        {buttons}
                    </div>
                </div>
            </div>

            { props.slide === 0 && props.profiles.length >= 6 &&
                <div className="col-6">
                    <div className="d-none d-md-block d-xl-none">
                        <div className="slideshow-profiles-container">
                            <div className="slideshow-profiles-subcontainer">
                                <div className="slideshow-profiles-subcontainer-top-tablet">
                                    <div className="slideshow-profiles-circle-table" style={{backgroundImage: 'url('+props.profiles[0]+')'}}></div>
                                    <div className="slideshow-profiles-circle-table" style={{backgroundImage: 'url('+props.profiles[1]+')'}}></div>
                                </div>
                                <div className="slideshow-profiles-subcontainer-bottom-tablet">
                                    <div className="slideshow-profiles-circle-table-offset"></div>
                                    <div className="slideshow-profiles-circle-table" style={{backgroundImage: 'url('+props.profiles[2]+')'}}></div>
                                    <div className="slideshow-profiles-circle-table" style={{backgroundImage: 'url('+props.profiles[3]+')'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-xl-block">
                        <div className="slideshow-profiles-container">
                            <div className="slideshow-profiles-subcontainer">
                                <div className="slideshow-profiles-subcontainer-top-desktop">
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[0]+')'}}></div>
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[1]+')'}}></div>
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[2]+')'}}></div>
                                </div>
                                <div className="slideshow-profiles-subcontainer-bottom-desktop">
                                    <div className="slideshow-profiles-circle-desktop-offset"></div>
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[3]+')'}}></div>
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[4]+')'}}></div>
                                    <div className="slideshow-profiles-circle-desktop" style={{backgroundImage: 'url('+props.profiles[5]+')'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

// Props: slide index (int), slide current (int), type (light, gray or dark), category, title
function ElementSlideshowThumbnail(props)
{
    let style = {};
    switch (props.type)
    {
        case 'white':   if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#8c8c8c'
                            }
                        }
                        break;

        case 'gray':    if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#646464'
                            }
                        }
                        break;

        case 'linkedin':if (props.selected)
                        {
                            style = {
                                color: '#ffffff'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#d6d6d6'
                            }
                        }
                        break;
                    
        default:        if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#646464'
                            }
                        }
                        break;
    }

    return (
        <div className="slideshow-thumbnail noselect" style={style}>
            { props.selected === true &&
                <div className={'slideshow-thumbnail-timer-'+props.type}>
                    <div className={'slideshow-thumbnail-timer-animation-'+props.color}></div>
                </div>
            }

            { props.selected === false &&
                <div className={'slideshow-thumbnail-timer-'+props.type}>
                </div>
            }
            
            <div className="slideshow-thumbnail-category">
                {props.category}
            </div>

            <div className="slideshow-thumbnail-text">
                {props.title}
            </div>
        </div>
    );
}

// Props: title, subtitle, filled, slug, image, text_checkmark1, text_checkmark2, text_checkmark3, translations
function ElementVacancyPreview(props)
{
    return (
        <div className="col-12">
            <Link to={'/vacatures/'+props.slug}>
                <div className="panel-content-vacancy-container">
                    { /* XS, SM */ }
                    <div className="d-block d-md-none">
                        { props.image !== null && props.image !== '' &&
                            <div className="panel-content-vacancy-logo-mobile" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        }

                        { props.filled === false &&
                            <div className="panel-content-vacancy-title-mobile">
                                <ElementHeaderH2
                                    text={props.title} />
                            </div>
                        }

                        { props.filled === true &&
                            <div className="panel-content-vacancy-title-mobile">
                                <s>
                                    <ElementHeaderH2
                                        text={props.title} />
                                </s>
                            </div>
                        }
                    </div>

                    { /* MD */ }
                    <div className="d-none d-md-block d-lg-none">
                        { props.filled === false &&
                            <div className="panel-content-vacancy-title-tablet">
                                <ElementHeaderH2
                                    text={props.title} />
                            </div>
                        }

                        { props.filled === true &&
                            <div className="panel-content-vacancy-title-tablet">
                                <s>
                                    <ElementHeaderH2
                                        text={props.title} />
                                </s>
                            </div>
                        }
                        
                        { props.image !== null && props.image !== '' &&
                            <div className="panel-content-vacancy-logo-tablet" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        }
                    </div>

                    { /* LG, XL, XXL */ }
                    <div className="d-none d-lg-block">
                        { props.filled === false &&
                            <div className="panel-content-vacancy-title-desktop">
                                <ElementHeaderH2
                                    text={props.title} />
                            </div>
                        }

                        { props.filled === true &&
                            <div className="panel-content-vacancy-title-desktop">
                                <s>
                                    <ElementHeaderH2
                                        text={props.title} />
                                </s>
                            </div>
                        }
                    
                        { props.image !== null && props.image !== '' &&
                            <div className="panel-content-vacancy-logo-desktop" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        }
                    </div>

                    <div className="panel-content-vacancy-subtitle">{props.subtitle}</div>

                    <div className="panel-content-vacancy-checkmark-container">
                        <div className="d-block d-lg-none">
                            <div className="panel-content-vacancy-checkmark-mobile">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark1}</span>
                            </div>
                            <div className="panel-content-vacancy-checkmark-mobile">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark2}</span>
                            </div>
                            <div className="panel-content-vacancy-checkmark-mobile">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark3}</span>
                            </div>
                        </div>
                        <div className="d-none d-lg-block">
                            <div className="panel-content-vacancy-checkmark-desktop">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark1}</span>
                            </div>
                            <div className="panel-content-vacancy-checkmark-desktop">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark2}</span>
                            </div>
                            <div className="panel-content-vacancy-checkmark-desktop">
                                <span className="panel-content-vacancy-checkmark-icon"></span>
                                <span className="panel-content-vacancy-checkmark-text">{props.text_checkmark3}</span>
                            </div>
                        </div>
                    </div>

                    { props.filled === true &&
                        <div className="panel-content-vacancy-filled">
                            {props.translations['content_vacancy_filled']}
                        </div>
                    }

                    <div className="panel-button-vacancy">
                        {props.translations['content_vacancy_button']}
                    </div>
                </div>
            </Link>
        </div>
    );
}

export { ElementClientsThumbnail, ElementDebugResponsive, ElementFooterLink, ElementHeaderH1, ElementHeaderH2, ElementHeaderH2Checkmark, ElementNewsThumbnail, ElementPanelHeader, ElementProfileInternal, ElementProfileConsultant, ElementProfileConsultantExternal, ElementSlideshowContent, ElementSlideshowThumbnail, ElementVacancyPreview }