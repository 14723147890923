import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementClientsThumbnail, ElementHeaderH1, ElementHeaderH2, ElementHeaderH2Checkmark, ElementNewsThumbnail, ElementProfileInternal, ElementProfileConsultant, ElementProfileConsultantExternal, ElementSlideshowContent, ElementSlideshowThumbnail, ElementFooterLink, ElementVacancyPreview } from './Element';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

// Props: debug, ip_address, translations, upload_images
function SectionAboutus(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'aboutus-1',
                        'aboutus-2',
                        'aboutus-3',
                        'aboutus-4',
                        'aboutus-5',
                        'aboutus-6',
                        'aboutus-7'
                    ]
                };

                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });

                content_set_data(response.data);
                // <list> content text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <React.Fragment>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH1
                                text={props.translations['content_aboutus_title']} />
                            { content_data !== null &&
                                <div className="panel-content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_aboutus_why']} />
                            { content_data !== null &&
                                <div className="panel-content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1]) }}></div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <div className="panel-clients-icon-container">
                                <div className="panel-clients-icon-client"></div>
                                <h3 className="panel-clients-icon-title">{props.translations['content_aboutus_customeroriented']}</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="panel-clients-icon-container">
                                <div className="panel-clients-icon-authentic"></div>
                                <h3 className="panel-clients-icon-title">{props.translations['content_aboutus_authentic']}</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="panel-clients-icon-container">
                                <div className="panel-clients-icon-speaker"></div>
                                <h3 className="panel-clients-icon-title">{props.translations['content_aboutus_entrepreneurial']}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous panel-section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_aboutus_dna']} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[2]) }}></div>
                            }
                        </div>
                        <div className="col-6">
                            <img src="/image/element_dna.jpg" alt="Level Finance family" width="100%" style={{borderRadius:'24px'}} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_aboutus_sustainable']} />
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[3]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous panel-section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_aboutus_promise']} />
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[4]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <ElementHeaderH2Checkmark
                                text={props.translations['content_aboutus_check_candidates']} />

                            { content_data !== null &&
                                <div className="panel-content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[5]) }}></div>
                            }
                        </div>
                        <div className="col-12 col-md-6">
                            <ElementHeaderH2Checkmark
                                text={props.translations['content_aboutus_check_clients']} />

                            { content_data !== null &&
                                <div className="panel-content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[6]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: ip_address, translations
function SectionACT(props)
{
    return (
        <section className="panel-section-continuous">
            <div className="container">
                <div className="row">
                    <ElementHeaderH2
                        text={props.translations['content_act_title']} />
                </div>
                <div className="panel-path-container">
                    <div className="row">
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="panel-path-subcontainer">
                                <div className="panel-path-subcontainer-icon">
                                    <div className="panel-path-icon-addedvalue"></div>
                                </div>
                                <div className="panel-path-title">{props.translations['content_path_title_addedvalue']}</div>
                                <p className="panel-path-text">{props.translations['content_path_addedvalue']}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-6">
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <div className="d-none d-md-block">
                                <div className="panel-path-curve-container">
                                    <div className="panel-path-curve-desktop"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-6">
                        </div>
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="panel-path-subcontainer">
                                <div className="panel-path-subcontainer-icon">
                                    <div className="panel-path-icon-placeperson"></div>
                                </div>
                                <div className="panel-path-title">{props.translations['content_path_title_placeperson']}</div>
                                <p className="panel-path-text">{props.translations['content_path_placeperson']}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <div className="d-none d-md-block">
                                <div className="panel-path-curve-container">
                                    <div className="panel-path-curve-desktop-mirror"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="panel-path-subcontainer">
                                <div className="panel-path-subcontainer-icon">
                                    <div className="panel-path-icon-act"></div>
                                </div>
                                <div className="panel-path-title">{props.translations['content_path_title_act']}</div>
                                <p className="panel-path-text">{props.translations['content_path_act']}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-6">
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="d-none d-md-block">
                                <div className="panel-path-curve-container">
                                    <div className="panel-path-curve-desktop"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-6">
                        </div>
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="panel-path-subcontainer">
                                <div className="panel-path-subcontainer-icon">
                                    <div className="panel-path-icon-guarantee"></div>
                                </div>
                                <div className="panel-path-title">{props.translations['content_path_title_guarantee']}</div>
                                <p className="panel-path-text">{props.translations['content_path_guarantee']}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: ip_address, translations
function SectionCareermatch(props)
{
    return (
        <section className="panel-section-continuous" id="linkedin">
            <div className="container">
                <div className="row">
                    <ElementHeaderH2
                        text={props.translations['content_careermatch_title1']} />
                </div>
                <div className="row">
                    <div className="col-12">
                        <a href="https://www.linkedin.com/company/levelfinance/" target="_blank" rel="noreferrer">
                            <div className="panel-content-careermatch-container">
                                <div className="panel-content-careermatch-title">{props.translations['content_careermatch_title2']}</div>

                                <div className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.translations['content_careermatch_text']) }}></div>

                                <div className="panel-button-linkedin">
                                    <div className="panel-button-linkedin-icon"></div>{props.translations['content_careermatch_button']}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionClients(props)
{
    // Prop functions
    const function_loaded1_set = props.function_loaded1_set;
    const function_loaded2_set = props.function_loaded2_set;

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'clients-1',
                        'clients-2', 
                        'clients-deta',
                        'clients-wervingselectie',
                        'clients-zzp',
                        'clients-review'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });
                
                content_set_data(response.data);
                // <list> content text

                function_loaded1_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded1_set]);

    const [clients_data, clients_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/clients_overview');

                clients_set_data(response.data)
                // <str> clients title
                // <str> clients date
                // <str> clients link
                // <str> clients image
                // <str> clients text

                function_loaded2_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded2_set]);

    let clients = [];
    if (clients_data !== null)
    {
        for (let i = 0; i < parseInt(clients_data.length); i++)
        {
            clients.push(
                <React.Fragment key={i}>
                    <ElementClientsThumbnail
                        title={clients_data[i][0]}
                        date={clients_data[i][1]}
                        link={clients_data[i][2]}
                        image={props.upload_images+'/'+clients_data[i][3]}
                        text={clients_data[i][4]}
                        button={props.translations['content_clients_button']} />
                </React.Fragment>
            );
        }
    }
    
    return (
        <React.Fragment>
            <img src="/image/banner_clients.jpg" alt="Level Finance klanten" width="100%" />
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH1
                                text={props.translations['content_clients_title']} />
                            
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous panel-section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_clients_service']} />
                            
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-4" id="detacheren">
                            <ElementHeaderH2Checkmark
                                text={props.translations['content_clients_deta']} />

                            { content_data !== null &&
                                <div className="panel-content-paragraph">
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[2]) }}></div>

                                    <Link to="/ons-team?scroll=consultants" className="panel-button-primary">{props.translations['content_profiles_button']}</Link>

                                    <Link to="/werken-bij" className="panel-button-primary">{props.translations['content_clients_workas']}</Link>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-xl-4" id="werving-en-selectie">
                            <ElementHeaderH2Checkmark
                                text={props.translations['content_clients_wervingselectie']} />

                            { content_data !== null &&
                                <div className="panel-content-paragraph">
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[3]) }}></div>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-xl-4">
                            <ElementHeaderH2Checkmark
                                text={props.translations['content_clients_zzp']} />

                            { content_data !== null &&
                                <div className="panel-content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[4]) }}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous panel-section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_clients_review']} />
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[5]) }}></div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        {clients}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: ip_address, translations
function SectionContact(props)
{
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_dropdown, form_set_dropdown] = useState(props.translations['contactform_dropdown1']);
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    // Update default dropdown when translation has been loaded
    useEffect(() => {
        form_set_dropdown(props.translations['contactform_dropdown1']);
    }, [props.translations,]);

    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone,
                    email: form_email,
                    dropdown: form_dropdown,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept
                }

                await axios.post(props.ip_address+'/backend/levelfinance/api/form_contact', data);
                
                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();
        
        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';

    let form = '';
    if (form_error === false)
    {
        if (form_sent === false)
        {
            if (form_loading === false)
            {
                form = (
                    <form onSubmit={event => handleSubmit(event)}>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_name"
                                    type="text"
                                    maxLength="128"
                                    value={form_name}
                                    autoComplete="off"
                                    onChange={event => form_set_name(event.target.value)}
                                    placeholder={props.translations['contactform_name']+' *'}
                                    required />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_phone"
                                    type="text"
                                    maxLength="128"
                                    value={form_phone}
                                    autoComplete="off"
                                    onChange={event => form_set_phone(event.target.value)}
                                    placeholder={props.translations['contactform_phone']+' *'}
                                    required />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_email"
                                    type="text"
                                    maxLength="128"
                                    value={form_email}
                                    autoComplete="off"
                                    onChange={event => form_set_email(event.target.value)}
                                    placeholder={props.translations['contactform_email']+' *'} 
                                    required />
                            </div>
                            <div className="col-12">
                                <select
                                    className="form-singleline-dropdown"
                                    name="form_dropdown"
                                    onChange={event => form_set_dropdown(event.target.value)} >
                                    
                                    <option value={props.translations['contactform_dropdown1']}>{props.translations['contactform_dropdown1']}</option>
                                    <option value={props.translations['contactform_dropdown2']}>{props.translations['contactform_dropdown2']}</option>
                                    <option value={props.translations['contactform_dropdown3']}>{props.translations['contactform_dropdown3']}</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-multiline"
                                    name="form_description"
                                    maxLength="4096"
                                    value={form_description}
                                    onChange={event => form_set_description(event.target.value)}
                                    placeholder={props.translations['contactform_description']} />
                            </div>
                            <div className="col-12">
                                <div className="form-checkbox-container">
                                    <label className={form_terms_class}>
                                        <input
                                            name="form_terms"
                                            type="checkbox"
                                            checked={form_terms}
                                            onChange={event => form_set_terms(event.target.checked)}
                                            required />
                                        <span className="form-text">{props.translations['contactform_terms']} <a href='/privacyverklaring' className="body-link" target="_blank" rel="noopener noreferrer">{props.translations['contactform_privacy']}</a>. *</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <input
                                    className="form-accept"
                                    name="form_accept"
                                    type="checkbox"
                                    checked={form_accept}
                                    onChange={event => form_set_accept(event.target.checked)} />
                            </div>
                            <div className="col-12">
                                <button onClick = {() => {}} className="form-send"><span>{props.translations['contactform_send']}</span></button>
                            </div>
                        </div>
                    </form>
                );
            }
        }
        else
        {
            form = (
                <div>
                    {props.translations['contactform_complete']}
                </div>
            );
        }
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="form-loading"></div>
        );
    }

    let error = '';
    if (form_error === true)
    {
        error = (
            <div className="form-error">{props.translations['contactform_error']}</div>
        );
    }

    return (
        <React.Fragment>
            <section className="form-section" id="contact-form">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-block d-md-none">
                            <div className="form-header">
                                <ElementHeaderH2 text={props.translations['contactform_title']} />
                                <p className="panel-content-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['contactform_text'])}}></p>
                            </div>
                        </div>
                        <div className="col-12 d-none d-md-block">
                            <div className="form-header">
                                <ElementHeaderH2 text={props.translations['contactform_title']} />
                                <p className="panel-content-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['contactform_text'])}}></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {loading}
                    {form}
                    {error}
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: ip_address, translations
function SectionDisclaimer(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['disclaimer']
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });
                
                content_set_data(response.data);
                // <str> content text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <ElementHeaderH1
                        text={props.translations['content_disclaimer_title']} />
                </div>
                <div className="row">
                    <div className="col">
                        { content_data !== null &&
                            <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: translations
function SectionFooter(props)
{
    return (
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="footer-spacing">
                            <div className="footer-company-logo"></div>
                        </div>

                        <div className="footer-spacing">
                            <div className="footer-company-container">
                                <div className="footer-company-paragraph">
                                    <div className="footer-text">
                                        <b>{props.translations['footer_mission']}</b><br/>
                                        {props.translations['footer_slogan']}
                                    </div>
                                </div>
                                <div className="footer-company-paragraph">
                                    <div className="footer-text">
                                        <b>{props.translations['footer_office']}</b><br/>
                                        Vogt 21<br/>
                                        6422 RK Heerlen<br/>
                                        Nederland<br/>
                                    </div>
                                </div>
                                <div className="footer-company-paragraph">
                                    <div className="footer-text">
                                        <b>{props.translations['footer_updates']}</b><br/>
                                        {props.translations['footer_updates_text']}<br/>

                                        <a href="https://www.linkedin.com/company/levelfinance/" target="_blank" rel="noreferrer" className="footer-button-linkedin">{props.translations['footer_linkedin_button']}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-spacing">
                            <div>
                                <ElementFooterLink
                                    to="/"
                                    text={props.translations['navigation_home']} />

                                <ElementFooterLink
                                    to="/werken-bij"
                                    text={props.translations['navigation_work_at']} />

                                <ElementFooterLink
                                    to="/opdrachtgevers"
                                    text={props.translations['navigation_clients']} />

                                <ElementFooterLink
                                    to="/over-ons"
                                    text={props.translations['navigation_aboutus']} />

                                <ElementFooterLink
                                    to="/ons-team"
                                    text={props.translations['navigation_team']} />

                                <ElementFooterLink
                                    to="/vacatures"
                                    text={props.translations['navigation_vacancies']} />

                                <ElementFooterLink
                                    to="/nieuws"
                                    text={props.translations['navigation_news']} />

                                <ElementFooterLink
                                    to="/vrijwaring"
                                    text={props.translations['navigation_disclaimer']} />

                                <ElementFooterLink
                                    to="/privacyverklaring"
                                    text={props.translations['navigation_privacy']} />

                                <ElementFooterLink
                                    to="/algemene-voorwaarden"
                                    text={props.translations['navigation_terms']} />
                            </div>
                        </div>

                        <hr className="footer-line" />

                        <div className="footer-spacing">
                            <span className="footer-text">
                                Inhoud ©{new Date().getFullYear()} Level Finance BV. KVK 80536190. Platform ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://thissen.ai/" target="_blank" rel="noreferrer">Thissen AI®</a>. Deze website is GDPR-compliant en maakt geen gebruik van tracking cookies. Alle rechten voorbehouden. 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: ip_address, translations
function SectionIntro(props)
{
    return (
        <section className="panel-section-continuous">
            <div className="container">
                <div className="row">
                    <ElementHeaderH2
                        text={props.translations['content_intro_title']} />
                </div>
                <div className="row">
                    <div className="col-12">
                        <div>{props.translations['content_intro_mission']}</div>
                        <div>{props.translations['content_intro_slogan']}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="panel-checks-title"><span className="panel-checks-number">1</span>{props.translations['content_intro_subtitle1']}</div>
                        <div className="panel-checks-text">{props.translations['content_intro_subtext1']}</div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="panel-checks-title"><span className="panel-checks-number">2</span>{props.translations['content_intro_subtitle2']}</div>
                        <div className="panel-checks-text">{props.translations['content_intro_subtext2']}</div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="panel-checks-title"><span className="panel-checks-number">3</span>{props.translations['content_intro_subtitle3']}</div>
                        <div className="panel-checks-text">{props.translations['content_intro_subtext3']}</div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: ip_address, translations
function SectionNavigation(props)
{
    // Expand (mobile)
    const [expanded, setExpanded] = useState(false);

    // Profiles available count
    const [profiles_total, profile_set_total] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/profileconsultant_available_total');

                profile_set_total(response.data);
                // <int> profiles available total

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container-sm">
                    <Navbar expanded={expanded} expand="xl" variant="light" className="navbar-container">
                        { /* Logo */ }
                        <Link to="/" className="navbar-title" onClick={() => setExpanded(false)}></Link>
                        
                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => setExpanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right">
                            <Nav>
                                <div className="navbar-listitem">
                                    <Link to="/werken-bij" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_work_at']}</Link>
                                </div>
                                
                                <div className="navbar-listitem">
                                    <Link to="/opdrachtgevers" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_clients']}</Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/over-ons" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_aboutus']}</Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/ons-team"  className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_team']}
                                    { profiles_total > 0 &&
                                        <span className="navbar-item-notification1">{profiles_total} <span className="navbar-item-notification-clockwise"></span></span>
                                    }
                                    </Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/vacatures" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_vacancies']}</Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/nieuws" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_news']}</Link>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
            <section className="navbar-spacing"></section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, upload_images, translations
function SectionNewsArticle(props)
{
    const {news_slug} = useParams();
    const [news_data, news_set_data] = useState([null, null, null, null]);

    const navigate = useNavigate();

    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    news_slug: news_slug
                }

                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/news', {params});

                news_set_data(response.data);
                // <str> news title
                // <str> news date (pre-formatted)
                // <str> news image
                // <str> news text
                
                if (props.debug === true)
                    console.log(response.data);   
            }

            catch (error)
            {
                navigate('/nieuws');
            }
        }

        get();
    }, [props.debug, props.ip_address, news_slug, navigate]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="panel-content-column">
                            <ElementHeaderH1
                                text={news_data[0]} />
                            <div className="panel-content-news-date">{news_data[1]}
                            </div>

                            { news_data[2] !== null && news_data[2] !== '' &&
                                <div className="d-block d-lg-none">
                                    <img src={props.upload_images+'/'+news_data[2]} className="panel-content-news-contentimage" width="100%" alt="Level Finance nieuws" />
                                </div>
                            }

                            <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news_data[3])}}></div>
                            <Link to='/nieuws'>
                                <div className="panel-button-primary"><span className="panel-button-icon-arrowleft"></span>{props.translations['navigation_to_news']}</div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-6 d-none d-lg-block">
                        { news_data[2] !== null && news_data[2] !== '' &&
                            <div className="panel-content-column-sideimage" style={{backgroundImage: 'url('+props.upload_images+'/'+news_data[2]+')'}}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionNewsLatest(props)
{
    const [news_data, news_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/news_latest');
                
                news_set_data(response.data)
                // <str> news title
                // <str> news date
                // <str> news slug
                // <str> news image
                // <str> news text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let news = [];
    if (news_data !== null)
    {
        for (let i = 0; i < parseInt(news_data.length); i++)
        {
            news.push(
                <React.Fragment key={i}>
                    <ElementNewsThumbnail
                        title={news_data[i][0]}
                        date={news_data[i][1]}
                        text={news_data[i][4]}
                        image={props.upload_images+'/'+news_data[i][3]}
                        slug={'/nieuws/'+news_data[i][2]}
                        button={props.translations['content_news_button']} />
                </React.Fragment>
            );
        }
    }

    return (
        <section className="panel-section-continuous">
            <div className="container">
                <div className="row">
                    <ElementHeaderH2
                        text={props.translations['content_news_latest']} />
                </div>
                <div className="row">
                    {news}
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionNewsOverview(props)
{
    const [news_data, news_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/news_overview');

                news_set_data(response.data)
                // <str> news title
                // <str> news date
                // <str> news slug
                // <str> news image
                // <str> news text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let news = [];
    if (news_data !== null)
    {
        for (let i = 0; i < parseInt(news_data.length); i++)
        {
            news.push(
                <React.Fragment key={i}>
                    <ElementNewsThumbnail
                        title={news_data[i][0]}
                        date={news_data[i][1]}
                        text={news_data[i][4]}
                        image={props.upload_images+'/'+news_data[i][3]}
                        slug={'/nieuws/'+news_data[i][2]}
                        button={props.translations['content_news_button']} />
                </React.Fragment>
            );
        }
    }
    
    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <ElementHeaderH1
                        text={props.translations['content_news_title']} />
                </div>
                <div className="row">
                    {news}
                </div>
            </div>
        </section>
    );
}

// Props: ip_address, translations
function SectionPrivacy(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['privacy',]
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });
                content_set_data(response.data);
                // <str> content text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <ElementHeaderH1
                        text={props.translations['content_privacy_title']} />
                </div>
                <div className="row">
                    <div className="col">
                        { content_data !== null &&
                            <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionProfilesOverview(props)
{
    // Prop functions
    const function_loaded1_set = props.function_loaded1_set;
    const function_loaded2_set = props.function_loaded2_set;

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['team']
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });

                content_set_data(response.data);
                // <list> content text

                function_loaded1_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded1_set]);

    const [profile_data, profile_set_data] = useState([null, null, null]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/profile_overview');

                profile_set_data(response.data);
                // 3x list (internal, consultant, consultant external)

                // List internal
                // <str> name
                // <str> title
                // <str> image
                // <str> text_profile
                // <str> phone
                // <str> email
                // <str> linkedin

                // List consultant
                // <str> name
                // <str> title
                // <str> image
                // <str> text_profile
                // <str> text_qualities
                // <date> availablefrom
                // <bool> availablenow

                // List consultantexternal

                // <str> profile name
                // <str> profile title
                // <str> profile company
                // <str> profile image
                // <str> profile text_profile
                // <str> profile externallink
                // <str> profile linkedin

                function_loaded2_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded2_set]);

    let profiles_internal = [];
    let profiles_consultant = [];
    let profiles_consultantexternal = [];
    if (profile_data[0] !== null && profile_data[1] !== null && profile_data[2] !== null)
    {
        for (let i = 0; i < parseInt(profile_data[0].length); i++)
        {
            // panel-content-profile-logo-levelfinance

            profiles_internal.push(
                <React.Fragment key={i}>
                    <ElementProfileInternal
                        name={profile_data[0][i][0]}
                        title={profile_data[0][i][1]}
                        image={props.upload_images+'/'+profile_data[0][i][2]}
                        text_profile={profile_data[0][i][3]}
                        phone={profile_data[0][i][4]}
                        email={profile_data[0][i][5]}
                        linkedin={profile_data[0][i][6]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }

        for (let i = 0; i < parseInt(profile_data[1].length); i++)
        {
            profiles_consultant.push(
                <React.Fragment key={i}>
                    <ElementProfileConsultant
                        name={profile_data[1][i][0]}
                        title={profile_data[1][i][1]}
                        image={props.upload_images+'/'+profile_data[1][i][2]}
                        text_profile={profile_data[1][i][3]}
                        text_qualities={profile_data[1][i][4]}
                        availablefrom={profile_data[1][i][5]}
                        availablenow={profile_data[1][i][6]}
                        linkedin={profile_data[1][i][7]}
                        businessmanager={profile_data[1][i][8]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
        
        for (let i = 0; i < parseInt(profile_data[2].length); i++)
        {
            profiles_consultantexternal.push(
                <React.Fragment key={i}>
                    <ElementProfileConsultantExternal
                        name={profile_data[2][i][0]}
                        title={profile_data[2][i][1]}
                        company={profile_data[2][i][2]}
                        image={props.upload_images+'/'+profile_data[2][i][3]}
                        text_profile={profile_data[2][i][4]}
                        externallink={profile_data[2][i][5]}
                        linkedin={profile_data[2][i][6]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
    }
    
    return (
        <React.Fragment>
            <section className="panel-section-single ">
                <div className="container">
                    <div className="row">
                        <ElementHeaderH1
                            text={props.translations['content_profiles_title']} />

                        { content_data !== null &&
                            <div className="panel-content-paragraph">
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                
                                <HashLink smooth to="#consultants" className="panel-button-primary">{props.translations['content_profiles_button']}</HashLink>
                            </div>
                        }
                    </div>
                    <div className="row panel-content-profile-container">
                        {profiles_internal}
                    </div>
                    <div id="consultants"></div>
                    <div className="row">
                        <ElementHeaderH2
                            text={props.translations['content_profiles_consultant']} />
                    </div>
                    <div className="row panel-content-profile-container">
                        {profiles_consultant}
                    </div>
                    <div className="row">
                        <ElementHeaderH2
                            text={props.translations['content_profiles_consultantexternal']} />
                    </div>
                    <div className="row panel-content-profile-container">
                        {profiles_consultantexternal}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

// Props: debug, ip_address, translations, upload_images
function SectionSlideshow(props)
{
    function shuffle(array)
    {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {randomIndex = Math.floor(Math.random() * currentIndex); currentIndex--; [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];}
        return array;
    }
      
    // Slideshow data
    const [slideshow_current, slideshow_current_set] = useState(0);
    const [slideshow_previous, slideshow_previous_set] = useState(0);
    
    // Category, title text, type, interval color, background name, button, colortheme, photo src, photo alt tag
    const slideshow = [
        [
            props.translations['slideshow_detacheren_category'],
            props.translations['slideshow_detacheren_title'],
            props.translations['slideshow_detacheren_text'],
            'gray',
            'slideshow-background-deta',
            [{
                buttontype: 'link',
                to: '/opdrachtgevers?scroll=detacheren',
                class: 'primary',
                text: props.translations['slideshow_detacheren_button']
            }],
            'primary',
            null,
            null
        ],
        [
            props.translations['slideshow_wervingselectie_category'],
            props.translations['slideshow_wervingselectie_title'],
            props.translations['slideshow_wervingselectie_text'],
            'gray',
            'slideshow-background-wervingselectie',
            [{
                buttontype: 'link',
                to: '/opdrachtgevers?scroll=werving-en-selectie',
                class: 'primary',
                text: props.translations['slideshow_wervingselectie_button']
            }],
            'primary',
            null,
            null
        ],
        [
            props.translations['slideshow_zzp_category'],
            props.translations['slideshow_zzp_title'],
            props.translations['slideshow_zzp_text'],
            'gray',
            'slideshow-background-zzp',
            [{
                buttontype: 'link',
                to: '/werken-bij?scroll=zzp',
                hash: 'zzp',
                class: 'primary',
                text: props.translations['slideshow_zzp_button']
            }],
            'primary',
            null,
            null
        ] /*
        [
            props.translations['slideshow_linkedin_category'],
            props.translations['slideshow_linkedin_title'],
            props.translations['slideshow_linkedin_text'],
            'gray',
            'slideshow-background-linkedin',
            [{
                buttontype: 'hash',
                to: 'linkedin',
                class: 'primary',
                text: props.translations['slideshow_linkedin_button']
            }],
            'primary',
            null,
            null
        ]*/
    ];

    // Slideshow interval
    let slideshow_interval = useRef(null);

    useEffect(() => {
        slideshow_interval.current = setInterval(() => {
            clearInterval(slideshow_interval.current);

            let slideshow_new = (slideshow_current+1) % slideshow.length;
            slideshow_previous_set(slideshow_current);
            slideshow_current_set(slideshow_new);
        }, 12000);
    }, [slideshow_current, slideshow.length]);

    const setSlide = (slideshow_new) => {
        clearInterval(slideshow_interval.current);

        slideshow_previous_set(slideshow_current);
        slideshow_current_set(slideshow_new);
    };

    // Thumbnail slideshow
    const thumbnails_foreground = [];
    const thumbnails_background = [];
    for (let i = 0; i < slideshow.length; i++)
    {
        thumbnails_foreground.push(
            <div key={i} className="slideshow-thumbnail-flex" onClick={() => setSlide(i)}>
                <ElementSlideshowThumbnail
                    type={slideshow[slideshow_current][3]}
                    selected={i === slideshow_current}
                    category={slideshow[i][0]}
                    title={slideshow[i][1]}
                    text={slideshow[i][2]}
                    color={slideshow[i][6]} />
            </div>
        );

        thumbnails_background.push(
            <div key={i} className="slideshow-thumbnail-flex">
                <ElementSlideshowThumbnail
                    type={slideshow[slideshow_current][3]}
                    selected={i === slideshow_current}
                    category={slideshow[i][0]}
                    title={slideshow[i][1]}
                    text={slideshow[i][2]}
                    color={slideshow[i][6]} />
            </div>
        );
    }

    // Profile data
    const [profiles_data, profiles_set_data] = useState([]);
    
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/slideshow_profiles');
                
                if (props.debug === true)
                    console.log(response.data);
                
                let list = [...response.data];
                shuffle(list);

                profiles_set_data(list);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address])

    return (
        <section className="slideshow-section">
            { slideshow.length > 0 && profiles_data.length > 0 &&
                <React.Fragment>
                    <div className={'slideshow-content-overlay '+slideshow[slideshow_current][4]+' slideshow-fade-'+(1+slideshow_current)}>
                        <div className="container">
                            <ElementSlideshowContent
                                type={slideshow[slideshow_current][3]}
                                category={slideshow[slideshow_current][0]}
                                title={slideshow[slideshow_current][1]}
                                text={slideshow[slideshow_current][2]}
                                buttons={slideshow[slideshow_current][5]}
                                slide={slideshow_current}
                                profiles={[
                                    props.upload_images+'/'+profiles_data[0],
                                    props.upload_images+'/'+profiles_data[1],
                                    props.upload_images+'/'+profiles_data[2],
                                    props.upload_images+'/'+profiles_data[3],
                                    props.upload_images+'/'+profiles_data[4],
                                    props.upload_images+'/'+profiles_data[5],
                                    ]} />
                        </div>
                        <div className={'slideshow-thumbnail-gradient-'+slideshow[slideshow_current][3]}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_foreground}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'slideshow-content '+slideshow[slideshow_previous][4]}>
                        <div className="container">
                            <ElementSlideshowContent
                                type={slideshow[slideshow_previous][3]}
                                category={slideshow[slideshow_previous][0]}
                                title={slideshow[slideshow_previous][1]}
                                text={slideshow[slideshow_previous][2]}
                                buttons={slideshow[slideshow_previous][5]}
                                slide={slideshow_previous}
                                profiles={[
                                    props.upload_images+'/'+profiles_data[0],
                                    props.upload_images+'/'+profiles_data[1],
                                    props.upload_images+'/'+profiles_data[2],
                                    props.upload_images+'/'+profiles_data[3],
                                    props.upload_images+'/'+profiles_data[4],
                                    props.upload_images+'/'+profiles_data[5],
                                    ]} />
                        </div>
                        
                        <div className={'slideshow-thumbnail-gradient-'+slideshow[slideshow_current][3]}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_background}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </section>
    );
}

// Props: ip_address, translations
function SectionTermsconditions(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['terms-and-conditions']
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });
                content_set_data(response.data);
                // <str> content text

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <ElementHeaderH1
                        text={props.translations['content_termsconditions_title']} />
                </div>
                <div className="row">
                    <div className="col">
                        { content_data !== null &&
                            <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionVacancy(props)
{
    const {vacancy_slug} = useParams();
    const [vacancy_data, vacancy_set_data] = useState([null, null, null, null, null, null, null, null, null]);
    const [profile_data, profile_set_data] = useState([null, null, null, null]);

    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    vacancy_slug: vacancy_slug
                }

                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_info', {params});
                
                vacancy_set_data(response.data.slice(0, 9));
                // <str> vacancy title
                // <str> vacancy subtitle
                // <str> vacancy filled
                // <str> vacancy image_logo
                // <str> vacancy image_photo
                // <str> vacancy text_checkmark1
                // <str> vacancy text_checkmark2
                // <str> vacancy text_checkmark3
                // <str> vacancy text
                if (props.debug === true)
                    console.log(response.data.slice(0, 9));

                profile_set_data(response.data.slice(9, 13));
                // <str> profile name
                // <str> profile image
                // <str> profile phone
                // <str> profile email
                if (props.debug === true)
                    console.log(response.data.slice(9, 13)); 
            }

            catch (error)
            {
                navigate('/vacatures');
            }
        }

        get();
    }, [props.debug, props.ip_address, vacancy_slug, navigate]);
    
    const handleSubmit = (event) => {
        form_set_loading(true);

        const post = async () => {
            try
            {
                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone,
                    email: form_email,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept,
                    slug: vacancy_slug
                }

                await axios.post(props.ip_address+'/backend/levelfinance/api/form_vacancy', data);

                form_set_sent(true);
                form_set_loading(false);
            }

            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();

        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';

    let form = '';
    if (form_error === false)
    {
        if (form_sent === false)
        {
            if (form_loading === false)
            {
                form = (
                    <form onSubmit={event => handleSubmit(event)}>
                        <div className="col-12">
                            <input
                                className="form-singleline"
                                name="form_name"
                                type="text"
                                maxLength="128"
                                value={form_name}
                                autoComplete="off"
                                onChange={event => form_set_name(event.target.value)}
                                placeholder="Naam *"
                                required />
                        </div>
                        <div className="col-12">
                            <input
                                className="form-singleline"
                                name="form_email"
                                type="text"
                                maxLength="128"
                                value={form_email}
                                autoComplete="off"
                                onChange={event => form_set_email(event.target.value)}
                                placeholder="E-mail *"
                                required />
                        </div>
                        <div className="col-12">
                            <input
                                className="form-singleline"
                                name="form_phone"
                                type="text"
                                maxLength="128"
                                value={form_phone}
                                autoComplete="off"
                                onChange={event => form_set_phone(event.target.value)}
                                placeholder="Telefoon *" 
                                required />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="form-multiline"
                                name="form_description"
                                maxLength="4096"
                                value={form_description}
                                onChange={event => form_set_description(event.target.value)}
                                placeholder="Beschrijving" />
                        </div>
                        <div className="col-12">
                            <div className="form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name="form_terms"
                                        type="checkbox"
                                        checked={form_terms}
                                        onChange={event => form_set_terms(event.target.checked)}
                                        required />
                                    <span className="form-text">{props.translations['contactform_terms']} <a href='/privacyverklaring' className="body-link" target="_blank" rel="noopener noreferrer">{props.translations['contactform_privacy']}</a>. *</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input
                                className="form-accept"
                                name="form_accept"
                                type="checkbox"
                                checked={form_accept}
                                onChange={event => form_set_accept(event.target.checked)} />
                        </div>
                        <div className="col-12">
                            <button onClick = {() => {}} className="form-send"><span>{props.translations['contactform_send']}</span></button>
                        </div>
                    </form>
                );
            }
        }
        else
        {
            form = (
                <div>
                    {props.translations['contactform_complete']}
                </div>
            );
        }
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="form-loading"></div>
        );
    }

    let error = '';
    if (form_error === true)
    {
        error = (
            <div className="form-error">{props.translations['contactform_error']}</div>
        );
    }

    return (
        <React.Fragment>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-content-column">

                                { /* XS, SM */ }
                                <div className="d-block d-md-none">
                                    { vacancy_data[3] !== null && vacancy_data[3] !== '' &&
                                        <div className="panel-content-vacancy-logo-mobile" style={{backgroundImage: 'url('+props.upload_images+'/'+vacancy_data[3]+')'}}></div>
                                    }

                                    { vacancy_data[2] === false &&
                                        <div className="panel-content-vacancy-title-mobile">
                                            <ElementHeaderH1
                                                text={vacancy_data[0]} />
                                        </div>
                                    }

                                    { vacancy_data[2] === true &&
                                        <div className="panel-content-vacancy-title-mobile">
                                            <s>
                                                <ElementHeaderH1
                                                    text={vacancy_data[0]} />
                                            </s>
                                        </div>
                                    }
                                </div>

                                { /* MD */ }
                                <div className="d-none d-md-block d-lg-none">
                                    { vacancy_data[2] === false &&
                                        <div className="panel-content-vacancy-title-tablet">
                                            <ElementHeaderH1
                                                text={vacancy_data[0]} />
                                        </div>
                                    }

                                    { vacancy_data[2] === true &&
                                        <div className="panel-content-vacancy-title-tablet">
                                            <s>
                                                <ElementHeaderH1
                                                    text={vacancy_data[0]} />
                                            </s>
                                        </div>
                                    }
                                    
                                    { vacancy_data[3] !== null && vacancy_data[3] !== '' &&
                                        <div className="panel-content-vacancy-logo-tablet" style={{backgroundImage: 'url('+props.upload_images+'/'+vacancy_data[3]+')'}}></div>
                                    }
                                </div>

                                { /* LG, XL, XXL */ }
                                <div className="d-none d-lg-block">
                                    { vacancy_data[2] === false &&
                                        <div className="panel-content-vacancy-title-desktop">
                                            <ElementHeaderH1
                                                text={vacancy_data[0]} />
                                        </div>
                                    }

                                    { vacancy_data[2] === true &&
                                        <div className="panel-content-vacancy-title-desktop">
                                            <s>
                                                <ElementHeaderH1
                                                    text={vacancy_data[0]} />
                                            </s>
                                        </div>
                                    }
                                
                                    { vacancy_data[3] !== null && vacancy_data[3] !== '' &&
                                        <div className="panel-content-vacancy-logo-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+vacancy_data[3]+')'}}></div>
                                    }
                                </div>

                                <div className="panel-content-vacancy-subtitle">{vacancy_data[1]}</div>

                                <div className="panel-content-vacancy-checkmark-container">
                                    <div className="d-block d-lg-none">
                                        <div className="panel-content-vacancy-checkmark-mobile">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[5]}</span>
                                        </div>
                                        <div className="panel-content-vacancy-checkmark-mobile">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[6]}</span>
                                        </div>
                                        <div className="panel-content-vacancy-checkmark-mobile">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[7]}</span>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <div className="panel-content-vacancy-checkmark-desktop">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[5]}</span>
                                        </div>
                                        <div className="panel-content-vacancy-checkmark-desktop">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[6]}</span>
                                        </div>
                                        <div className="panel-content-vacancy-checkmark-desktop">
                                            <span className="panel-content-vacancy-checkmark-icon"></span>
                                            <span className="panel-content-vacancy-checkmark-text">{vacancy_data[7]}</span>
                                        </div>
                                    </div>
                                </div>

                                { vacancy_data[2] === true &&
                                    <div className="panel-content-vacancy-filled">
                                        {props.translations['content_vacancy_filled']}
                                    </div>
                                }

                                { vacancy_data[4] !== null && vacancy_data[4] !== '' &&
                                    <img src={props.upload_images+'/'+vacancy_data[4]} className="panel-content-vacancy-contentimage" width="100%" alt="Level Finance vacature" />
                                }

                                <div className="panel-content-vacancy-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(vacancy_data[8])}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous-last">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="panel-content-column">
                                <ElementHeaderH2 text={props.translations['content_vacancy_form_title']} />

                                <div className="panel-content-column-profile d-block d-md-none">
                                    <div className="panel-content-vacancy-profile-subcontainer">
                                        <div className="panel-content-vacancy-profile-picture" style={{backgroundImage: 'url('+props.upload_images+'/'+profile_data[1]}}></div>
                                        <div>
                                            <div className="panel-content-vacancy-profile-intro">{props.translations['content_vacancy_offeredby']}</div>
                                            <div className="panel-content-vacancy-profile-name"><div className="panel-content-vacancy-profile-name-star"></div> {profile_data[0]}</div><div className="panel-content-vacancy-profile-quote">{props.translations['content_vacancy_profile']}</div>
                                            
                                            <div className="panel-content-vacancy-profile-buttons">
                                                { profile_data[2] !== null &&
                                                    <a href={'tel:'+profile_data[2]}>
                                                        <div className="panel-content-contact-profile-button">
                                                            <div className="panel-content-contact-profile-icon-phone"></div>{profile_data[2]}
                                                        </div> 
                                                    </a>
                                                }

                                                { profile_data[3] !== null &&
                                                    <a href={"mailto:"+profile_data[3]}>
                                                        <div className="panel-content-contact-profile-button">
                                                            <div className="panel-content-contact-profile-icon-email"></div>Mail
                                                        </div> 
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel-content-text">
                                    {loading}
                                    {form}
                                    {error}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 d-none d-md-block">
                            <div className="panel-content-column-sideprofile">
                                <div className="panel-content-vacancy-profile-subcontainer">
                                    <div className="panel-content-vacancy-profile-picture" style={{backgroundImage: 'url('+props.upload_images+'/'+profile_data[1]}}></div>
                                    <div>
                                        <div className="panel-content-vacancy-profile-intro">{props.translations['content_vacancy_offeredby']}</div>
                                        <div className="panel-content-vacancy-profile-name"><div className="panel-content-vacancy-profile-name-star"></div> {profile_data[0]}</div><div className="panel-content-vacancy-profile-quote">{props.translations['content_vacancy_profile']}</div>
                                        
                                        <div className="panel-content-vacancy-profile-buttons">
                                            { profile_data[2] !== null &&
                                                <a href={'tel:'+profile_data[2]}>
                                                    <div className="panel-content-contact-profile-button">
                                                        <div className="panel-content-contact-profile-icon-phone"></div>{profile_data[2]}
                                                    </div> 
                                                </a>
                                            }

                                            { profile_data[3] !== null &&
                                                <a href={"mailto:"+profile_data[3]}>
                                                    <div className="panel-content-contact-profile-button">
                                                        <div className="panel-content-contact-profile-icon-email"></div>Mail
                                                    </div> 
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionVacancyExternal(props)
{
    // Vacanties count
    const [vacancy_total, vacancy_set_total] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_total');

                vacancy_set_total(response.data);
                // <int> vacancy total

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    // Vacancy data
    const [vacancy_data, vacancy_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_external');

                vacancy_set_data(response.data);
                // <str> vacancy title
                // <str> vacancy subtitle
                // <bool> vacancy bool
                // <str> vacancy slug
                // <str> vacancy image_logo
                // <str> vacancy text_checkmark1
                // <str> vacancy text_checkmark2
                // <str> vacancy text_checkmark3
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let vacancy = []
    if (vacancy_data !== null)
    {  
        for (let i = 0; i < parseInt(vacancy_data.length); i++)
        {
            vacancy.push(
                <React.Fragment key={i}>
                    <ElementVacancyPreview
                        title={vacancy_data[i][0]}
                        subtitle={vacancy_data[i][1]}
                        filled={vacancy_data[i][2]}
                        slug={vacancy_data[i][3]} 
                        image={props.upload_images+'/'+vacancy_data[i][4]}
                        text_checkmark1={vacancy_data[i][5]}
                        text_checkmark2={vacancy_data[i][6]}
                        text_checkmark3={vacancy_data[i][7]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
    }
    
    return (
        <section className="panel-section-single">
            <div className="container">
                <div className="row">
                    <ElementHeaderH1
                        text={props.translations['content_vacancy_title']} />
                </div>
                <div className="row">
                    <div className="col">
                        { props.translations['content_vacancy_total'] !== undefined &&
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.translations['content_vacancy_total'].replace('$total', vacancy_total)) }}></div>
                        }
                    </div>
                </div>
                <div className="row">
                    {vacancy}
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionVacancyHighlight(props)
{
    const [vacancy_data, vacancy_set_data] = useState([]);

    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_highlighed');

                vacancy_set_data(response.data);
                // <str> vacancy title
                // <str> vacancy subtitle
                // <bool> vacancy filled
                // <str> vacancy slug
                // <str> vacancy image_logo
                // <str> vacancy text_checkmark1
                // <str> vacancy text_checkmark2
                // <str> vacancy text_checkmark3
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let vacancy = []
    if (vacancy_data !== null)
    {  
        for (let i = 0; i < parseInt(vacancy_data.length); i++)
        {
            vacancy.push(
                <React.Fragment key={i}>
                    <ElementVacancyPreview
                        title={vacancy_data[i][0]}
                        subtitle={vacancy_data[i][1]}
                        filled={vacancy_data[i][2]}
                        slug={vacancy_data[i][3]} 
                        image={props.upload_images+'/'+vacancy_data[i][4]}
                        text_checkmark1={vacancy_data[i][5]}
                        text_checkmark2={vacancy_data[i][6]}
                        text_checkmark3={vacancy_data[i][7]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
    }

    if (vacancy_data.length > 0)
    {
        return (
            <section className="panel-section-continuous-last">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_vacancy_highlighted']} />
                        </div>
                    </div>
                    <div className="row">
                        {vacancy}
                    </div>
                </div>
            </section>
        );
    }
    else
    {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

// Props: debug, ip_address, translations, upload_images
function SectionVacancyInternal(props)
{
    // Prop functions
    const function_loaded2_set = props.function_loaded2_set;

    // Vacancy data
    const [vacancy_data, vacancy_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_internal');

                vacancy_set_data(response.data);
                // <str> vacancy title
                // <str> vacancy subtitle
                // <bool> vacancy bool
                // <str> vacancy slug
                // <str> vacancy image_logo
                // <str> vacancy text_checkmark1
                // <str> vacancy text_checkmark2
                // <str> vacancy text_checkmark3

                function_loaded2_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded2_set]);

    let vacancy = []
    if (vacancy_data !== null)
    {  
        for (let i = 0; i < parseInt(vacancy_data.length); i++)
        {
            vacancy.push(
                <React.Fragment key={i}>
                    <ElementVacancyPreview
                        title={vacancy_data[i][0]}
                        subtitle={vacancy_data[i][1]}
                        filled={vacancy_data[i][2]}
                        slug={vacancy_data[i][3]} 
                        image={props.upload_images+'/'+vacancy_data[i][4]}
                        text_checkmark1={vacancy_data[i][5]}
                        text_checkmark2={vacancy_data[i][6]}
                        text_checkmark3={vacancy_data[i][7]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
    }

    return (
        <section className="panel-section-continuous-last">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ElementHeaderH2
                            text={props.translations['content_workat_vacancy']} />
                    </div>
                </div>
                <div className="row">
                    {vacancy}
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionVacancyZZP(props)
{
    // Prop functions
    const function_loaded5_set = props.function_loaded5_set;

    // Vacancy data
    const [vacancy_data, vacancy_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/vacancy_zzp');

                vacancy_set_data(response.data);
                // <str> vacancy title
                // <str> vacancy subtitle
                // <bool> vacancy bool
                // <str> vacancy slug
                // <str> vacancy image_logo
                // <str> vacancy text_checkmark1
                // <str> vacancy text_checkmark2
                // <str> vacancy text_checkmark3

                function_loaded5_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded5_set]);

    let vacancy = []
    if (vacancy_data !== null)
    {  
        for (let i = 0; i < parseInt(vacancy_data.length); i++)
        {
            vacancy.push(
                <React.Fragment key={i}>
                    <ElementVacancyPreview
                        title={vacancy_data[i][0]}
                        subtitle={vacancy_data[i][1]}
                        filled={vacancy_data[i][2]}
                        slug={vacancy_data[i][3]} 
                        image={props.upload_images+'/'+vacancy_data[i][4]}
                        text_checkmark1={vacancy_data[i][5]}
                        text_checkmark2={vacancy_data[i][6]}
                        text_checkmark3={vacancy_data[i][7]}
                        translations={props.translations} />
                </React.Fragment>
            );
        }
    }

    return (
        <section className="panel-section-continuous-last">
            <div className="container">
                <div className="row">
                    {vacancy}
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionWorkatInternal1(props)
{
    // Prop functions
    const function_loaded1_set = props.function_loaded1_set;

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['work-family',]
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });

                content_set_data(response.data);
                // <list> content text

                function_loaded1_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded1_set]);

    return (
        <React.Fragment>
            <section className="banner-section-workat">
                <div className="banner-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h1 className="banner-content-title-desktop">{props.translations['content_workat_title']}</h1>
                                <p className="banner-content-text">{props.translations['content_workat_text']}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="panel-section-continuous">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementHeaderH2
                                text={props.translations['content_workat_title_family']} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                            }
                        </div>
                        <div className="col-6">
                            <img src="/image/element_family.jpg" alt="Level Finance family" width="100%" style={{borderRadius:'24px'}} />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionWorkatInternal2(props)
{
    // Prop functions
    const function_loaded3_set = props.function_loaded3_set;

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['work-happiness', 'work-education',]
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });

                content_set_data(response.data);
                // <list> content text

                function_loaded3_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded3_set]);

    return (
        <React.Fragment>
            <section className="panel-section-continuous panel-section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <ElementHeaderH2
                                text={props.translations['content_workat_title_happiness']} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }} style={{marginBottom:'48px'}}></div>
                            }

                            <ElementHeaderH2
                                text={props.translations['content_workat_title_education']} />

                            { content_data !== null &&
                                <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1]) }}></div>
                            }
                        </div>
                        <div className="col-6">
                            <img src="/image/element_happiness.jpg" alt="Level Finance family" width="100%" style={{borderRadius:'24px'}} />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations, upload_images
function SectionWorkatZZP(props)
{
    // Prop functions
    const function_loaded4_set = props.function_loaded4_set;

    // Content
    const [content_data, content_set_data] = useState([null]);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['work-zzp']
                };
                
                const response = await axios.get(props.ip_address+'/backend/levelfinance/api/content', { params });
                
                content_set_data(response.data);
                // <list> content text

                function_loaded4_set(true);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, function_loaded4_set]);

    return (
        <section className="panel-section-continuous" id="zzp">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ElementHeaderH2
                            text={props.translations['content_workat_title_zzp']} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        { content_data !== null &&
                            <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export { SectionAboutus, SectionACT, SectionCareermatch, SectionClients, SectionContact, SectionDisclaimer, SectionFooter, SectionIntro, SectionNavigation, SectionNewsArticle, SectionNewsLatest, SectionNewsOverview, SectionPrivacy, SectionProfilesOverview, SectionSlideshow, SectionTermsconditions, SectionVacancy, SectionVacancyExternal, SectionVacancyHighlight, SectionVacancyInternal, SectionVacancyZZP, SectionWorkatInternal1, SectionWorkatInternal2, SectionWorkatZZP }